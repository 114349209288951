import React, { useState } from 'react'
import './styles.css'

const NhlLeadersCard = ({ title, data }) => {

    return (
        <div className='NhlLeadersContentContainer'>
            {data.map((leader, index) => (
                <div className='NhlLeadersDetails'>
                    <div className='NhlLeadersImgNameDetails'>
                        <img
                            width={100}
                            height={100}
                            style={{ borderRadius: "100px" }}
                            src={leader?.headshot}
                            alt={`${leader?.firstName?.default} ${leader?.lastName?.default}`}
                        />
                        <div style={{ display: 'flex', flexDirection: "column" }}>
                            <h5 style={{ color: "#fff", textTransform: "capitalize" }}><b>{title}</b></h5>
                            <h3 style={{color:"white"}}><b>{leader.firstName?.default} {leader.lastName?.default}</b></h3>
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                <img src={leader?.teamLogo} width={50} height={50} alt={`${leader?.firstName?.default} ${leader?.lastName?.default}card`} />
                                <h6 style={{margin:"0px" , color:'white'}}>{leader.teamAbbrev}</h6>
                            </div>
                        </div>
                    </div>
                    <div className='NhlLeadersValueContainer'>
                        <h2>{leader.value}</h2>
                        <h6 style={{ textTransform: "capitalize" }}> {title}</h6>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default NhlLeadersCard