import React, { useState } from 'react'
import './styles.css'
import rankings from '../../nbaRanking.json'
import { sortArrayAsc } from '../../helpers/getHelpers'

const NbaRankings = () => {
    const [nbaState , setNbaState] = useState('Eastern')

    const states =[
        {
            name:'Eastern',
        },
        {
            name:'Western'
        }
    ]

    return (
        <div className='nbaRankingWrapper'>
            <div className='nbaRankingText'>
                <h4>NBA</h4>
            </div>
            {/* <div className='nbaRankingContent'>
                {
                    rankings?.standingGroups.map((ranking) => {
                        return (
                            <div className='nbaRankingContainer'>
                                <h5>{ranking?.conference}</h5>
                                <div className='nbaRankingGamesContainer'>
                                    {
                                        sortArrayAsc(ranking?.teams).map((team) => {
                                            return (
                                                <div className='nbaRankingGames'>
                                                    <div className='nbaRankingGamesRank'>
                                                        <p>{team?.standings?.confRank}</p>
                                                    </div>
                                                    <div className='nbaRankingGamesImageContainer'>
                                                        <img src={`https://cdn.nba.com/logos/nba/${team?.profile?.id}/primary/L/logo.svg`} width={40} height={40} />
                                                    </div>
                                                    <div className='nbaRankingGamesNameContainer'>
                                                        <p>{team?.profile?.cityEn + " " + team?.profile?.nameEn}</p>
                                                    </div>
                                                    <div className='nbaRankingGamesWinLossContainer'>
                                                        <p>W/L: <b>{team?.standings?.wins}</b>/{team?.standings?.losses}</p>
                                                    </div>
                                                    <div className='nbaRankingGamesLast10Container'>
                                                        <p>Last 10: {team?.standings?.last10}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div> */}
            <div style={{display:"flex"}}>
                <div className='stateContainer'>
                    {
                        states?.map(state =>{
                            return(
                                <div className={state.name === nbaState ? "stateSelected" : 'stateNotSelected'} onClick={()=>setNbaState(state?.name)}>
                                    {state?.name}
                                </div>
                            )
                        })
                    }
                </div>
                <div className='nbaRankingsContainer'>
                    {
                        rankings?.standingGroups.map((ranking)=>{
                            return (
                                <>
                                    {
                                        ranking?.conference === nbaState &&
                                        sortArrayAsc(ranking?.teams)?.map((team)=>{
                                            return(
                                                <div className='nbaRankings'>
                                                    <div className='nbaRankingsContentContainer'>
                                                        <div>
                                                            <img src={`https://cdn.nba.com/logos/nba/${team?.profile?.id}/primary/L/logo.svg`} width={60} height={60} />
                                                            <div className='nbaRankingNameContainer'>
                                                                <p>{team?.profile?.cityEn}</p>
                                                                <p>{team?.profile?.nameEn}</p>
                                                            </div>
                                                        </div>
                                                        <div className='nbaRankingWinLossContainer'>
                                                            <p>Wins: <b>{team?.standings?.wins}</b></p>
                                                            <p>Losses: <b>{team?.standings?.losses}</b></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default NbaRankings