import React from 'react'
import './styles.css'
import premierleagueGoals from '../../premierLeagueGoals.json'

const PremierleagueGoals = () => {
    return (
        <div style={{ padding: "0px 64px" }}>
            <div className='nbaRankingText'>
                <h4>Premier League Best Scorer</h4>
            </div>
            <div style={{ display: "flex", gap: 16, overflowX: 'auto', paddingBottom: "8px" }}>
                {
                    premierleagueGoals?.content?.map((content) => {
                        return (
                            <div className='PremierleagueGoalsContent'>
                                <div className='PremierleagueGoalsContentOverlay'>
                                    <div className='PremierleagueGoalsContentOverlayContent'>
                                        <div style={{padding:"16px"}}>
                                            <h3>{content?.name?.display}</h3>
                                            <p>Rank : {content?.rank}</p>
                                            <div style={{ display: "flex", alignItems: "center", gap: '4px', marginTop: "16px" }}>
                                                <img src={`https://resources.premierleague.com/premierleague/badges/50/${content?.currentTeam?.altIds?.opta}@x2.png`} width={30} />
                                                <h6 style={{ margin: 0 }}>{content?.currentTeam?.name}</h6>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "flex-end", gap: '4px', marginTop: "8px" }}>
                                                <h3>Goals: {content?.value}</h3>
                                            </div>
                                        </div>
                                        <div style={{display:"flex"}}>
                                            <div style={{ display: "flex", alignItems: "flex-end" , marginRight:"20px"}}>
                                                <img src={`https://resources.premierleague.com/premierleague/photos/players/110x140/${content?.altIds?.opta}.png`} height={190} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default PremierleagueGoals