import React, { useState, useEffect } from 'react';

function YouTubeEmbed({ youtubeUrl }) {
    const [videoId, setVideoId] = useState('');

    useEffect(() => {
        // Extract the video ID from the URL
        const id = youtubeUrl.split('v=')[1];
        setVideoId(id);
    }, [youtubeUrl]);

    // Construct the embed URL using the video ID
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return (
        <div>
            {videoId && (
                <iframe
                    width="400px"
                    height="250px"
                    src={embedUrl}
                    style={{ borderRadius: '0px' }}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            )}
        </div>
    );
}

export default YouTubeEmbed;
