import React from 'react'
import './styles.css'
import nbaDrafts from '../../nbaDrafts.json'
import { cutText } from '../../helpers/getHelpers'
import { Accordion, Col, Row } from 'react-bootstrap'

const NbaDrafts = () => {
    const drafs = Object.groupBy(nbaDrafts, (draft) => draft.TeamFullName)
    return (
        <div className='NbaDraftsWrapper'>
            <div className='NbaDraftsText'>
                <h4>NBA Drafts</h4>
            </div>
            <div className='NbaDraftsContentContainer'>
                {/* {
                    nbaDrafts?.map((draft) => {
                        return (
                            <>
                                <div className='NbaDraftsContent'>
                                    <div>
                                        {
                                            draft?.Headshot ?
                                                <img src={`https://global.nba.com${draft?.Headshot}`} width={70} height={70} />
                                                :
                                                <img src={`https://cdn.nba.com/logos/nba/${draft?.TeamID}/primary/L/logo.svg`} width={70} height={70} />
                                        }
                                    </div>
                                    <div className='NbaDraftsPickNoContainer'>
                                        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
                                            <h6 style={{ margin: 0, fontSize: '14px' }}>#{draft?.PickNo}</h6>
                                            <p style={{ fontSize: '14px' }}><b>{draft?.TeamABR}</b></p>
                                        </div>
                                        <p style={{ fontSize: '14px' }}>{cutText(draft?.PlayerName, 13, 13)}</p>
                                    </div>
                                </div>

                            </>
                        )
                    })
                } */}

                <Row>
                    <Col md={6}>
                        {Object.keys(drafs).slice(0, 14).map((draft, index) => (
                            <Accordion key={draft}>
                                <Accordion.Item eventKey={String(index)}>
                                    <Accordion.Header className='accordionHeader'>
                                        <img
                                            src={`https://cdn.nba.com/logos/nba/${drafs[draft][0]?.TeamID}/primary/L/logo.svg`}
                                            alt="nba-team-logo"
                                            width={40}
                                        />
                                        {draft}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {
                                            drafs[draft]?.map(player => {
                                                return (
                                                    <div className='nbaAccordionBody'>
                                                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                                            <p>No: {player?.PickNo}</p>
                                                            <p>Position: {player?.Position}</p>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                                            <p>Height: {player?.Height}</p>
                                                            <p>Weight: {player?.Weight}lb</p>
                                                        </div>
                                                        <span>{player?.PlayerName}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        ))}
                    </Col>
                    <Col md={6}>
                        {Object.keys(drafs).slice(14, 27).map((draft, index) => {
                            return (
                                <Accordion key={draft}>
                                    <Accordion.Item eventKey={String(index)}>
                                        <Accordion.Header>
                                            <img
                                                src={`https://cdn.nba.com/logos/nba/${drafs[draft][0]?.TeamID}/primary/L/logo.svg`}
                                                alt="nba-team-logo"
                                                width={40}
                                            />
                                            {draft}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {
                                                drafs[draft]?.map(player => {
                                                    return (
                                                        <div className='nbaAccordionBody'>
                                                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                                                <p>No: {player?.PickNo}</p>
                                                                <p>Position: {player?.Position}</p>
                                                            </div>
                                                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                                                <p>Height: {player?.Height}</p>
                                                                <p>Weight: {player?.Weight}lb</p>
                                                            </div>
                                                            <span>{player?.PlayerName}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )

                        })}
                    </Col>
                </Row>

            </div>

        </div>
    )
}

export default NbaDrafts