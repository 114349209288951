import React, { useState } from 'react'
import './styles.css'
import nhlLeaders from '../../nhlLeaders.json'
import NhlLeadersCard from './nhlLeadersCard';

const NhlLeaders = () => {

    return (
        <div className='NhlLeadersWrapper'>
            <div className='nbaRankingText'>
                <h4>NHL Leaders</h4>
            </div>
            <div style={{display:'flex' , gap:16 , width:'100%'}}>
                <NhlLeadersCard title='goals' data={nhlLeaders.goals} />
                <NhlLeadersCard title='points' data={nhlLeaders.points} />
            </div>
            <div style={{display:'flex' , gap:16, width:'100%'}}>
                <NhlLeadersCard title='gaa' data={nhlLeaders.GAA} />
                <NhlLeadersCard title='wins' data={nhlLeaders.wins} />
            </div>

        </div>
    )
}

export default NhlLeaders