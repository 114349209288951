import axios from 'axios';
import { useLayoutEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getHighlights } from '../src/api';
import './App.css';
import Games from './components/games';
import Highlights from './components/highlights';
import IntroductionImage from './components/introductionImage';
import Navigation from './components/navigation';
import NbaDrafts from './components/nbaDrafts';
import NbaRankings from './components/nbaRanking';
import News from './components/news';
import NhlLeaders from './components/nhlLeaders';
import Reviews from './components/reviews';
import USALeagues from './components/usaLeagues';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import PremierleagueGoals from './components/PremierleagueGoals';
import NflTopClubs from './components/NflTopClubs';
import MlsPlayerStats from './components/MlsPlayerStats';


function App() {
  const [news, setNews] = useState([]);
  const [highlights, setHighlights] = useState([]);
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState([]);

  useLayoutEffect(() => {
    setLoading(true)
    axios.get('/api/news')
      .then(response => {
        setNews(response.data.items);
        setLoading(false)
      })
      .catch(error => console.log(error));

    getHighlights()?.then(response => {
      setLoading(false)
      setHighlights(response?.tvhighlights)
    })
      .catch(error => console.log(error));
    setLoading(false)
  }, []);


  return (
    <div style={{ overflowX: 'hidden' }}>
      <Navigation />
      <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
        <Row>
            <News news={news} loading={loading} />
            <PremierleagueGoals />
            <Highlights highlights={highlights} loading={loading} />
        </Row>
      </Col>

      <Container>
        <Row>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} style={{ padding: 16 }}>
            <NbaRankings />
          </Col>
          <NflTopClubs />
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} style={{ padding: 16 }}>
            <NbaDrafts firstRound={true} />
          </Col>
            <NhlLeaders />
        </Row>
        <Row>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
            <Games />
          </Col>
          <MlsPlayerStats />
        </Row>
      </Container>
      <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
        <Reviews />
      </Row>
      <Row xxl={12} xl={12} lg={12} md={12} sm={12}>
        <Footer />
      </Row>

    </div >
  );
}

export default App;
