import React from 'react'
import nflTopClubs from '../../nflTopClubs.json'
import './styles.css'

const NflTopClubs = () => {
    return (
        <div style={{ padding: '32px 0px' }}>
            <div className='nbaRankingText'>
                <h4>Top NHL Clubs</h4>
            </div>
            <div style={{ display: "flex", gap: 16,flexDirection:'column', marginTop:"16px"}}>
                {
                    nflTopClubs?.clubs?.map((club) => {
                        return (
                            <div className='NflTopClubsContent'>
                                <div style={{display:"flex" , alignItems:"center" , gap:"16px"}}>
                                    <img src={club?.logo} width={50} height={50}/>
                                    <h4 style={{margin:0}}>{club.name}</h4>
                                </div>
                                <div style={{display:"flex" , gap:"8px"}}>
                                    <h6 style={{margin:"0px"}}>FMG: {club?.fmg} - </h6>
                                    <h6 style={{margin:"0"}}>FG %: {club?.fg}</h6>
                                </div>
                                <div style={{display:"flex", gap:'8px'}}>
                                    <h6 style={{margin:"0px"}}>XPM: {club?.xpm} - </h6>
                                    <h6 style={{margin:"0px"}}>XP Pct: {club?.xp_pct}</h6>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default NflTopClubs