export const navLinks = [
    {
        id: 1,
        link: 'BasketBall',
        cName: 'navLink2',
    },
    {
        id: 2,
        link: 'FootBall',
        cName: 'navLink2',
    },
    {
        id: 3,
        link: 'BaseBall',
        cName: 'navLink2',
    },
    {
        id: 4,
        link: 'Cycling',
        cName: 'navLink2',
    },
    {
        id: 5,
        link: 'TableTennis',
        cName: 'navLink2',
    },
    {
        id: 6,
        link: 'Skiing',
        cName: 'navLink2',
    },
    {
        id: 7,
        link: 'Swimming',
        cName: 'navLink2',
    },
    {
        id: 8,
        link: 'Badminton',
        cName: 'navLink2',
    },
];
