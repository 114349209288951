import React from 'react';
import Rating from './rating';
import './styles.css';

import { Container } from 'react-bootstrap';
import avatar1 from '../../assets/images/avatar1.jpg';
import avatar10 from '../../assets/images/avatar10.jpg';
import avatar11 from '../../assets/images/avatar11.jpg';
import avatar12 from '../../assets/images/avatar12.jpg';
import avatar2 from '../../assets/images/avatar2.jpg';
import avatar3 from '../../assets/images/avatar3.jpg';
import avatar4 from '../../assets/images/avatar4.jpg';
import avatar5 from '../../assets/images/avatar5.jpg';
import avatar6 from '../../assets/images/avatar6.jpg';
import avatar7 from '../../assets/images/avatar7.jpg';
import avatar8 from '../../assets/images/avatar8.jpg';
import avatar9 from '../../assets/images/avatar9.jpg';

const Reviews = () => {
    const reviews = [
        {
            id: 1,
            name: "John Smith",
            description: "BetKing helped me win big with their expert betting tips and advice. Highly recommended!",
            rating: "5",
            avatar: avatar1,
            size: "default"
        },
        {
            id: 2,
            name: "Sarah Johnson",
            description: "I'm extremely grateful to BetKing for providing me with the best betting strategies. Great service!",
            rating: "5",
            avatar: avatar2,
            size: "default"
        },
        {
            id: 3,
            name: "Emily Roberts",
            description: "BetKing has been an absolute game-changer for me. I cannot express enough gratitude for their expertise and dedication in helping me place winning bets. When I first started, I felt lost and unsure. But thanks to PlatinumPunters's exceptional service, I now bet with confidence.",
            rating: "5",
            avatar: avatar3,
            size: "tall"
        },
        {
            id: 4,
            name: "Mark Davis",
            description: "From the moment I joined BetKing, their team was incredibly responsive and understanding. They took the time to listen to my needs and provided me with a clear plan of action. Their knowledge and experience in the betting world were evident throughout the entire process.",
            rating: "5",
            avatar: avatar4,
            size: "wide"
        },
        {
            id: 5,
            name: "Jennifer Wilson",
            description: "I had a great experience with BetKing. They were able to guide me to consistent wins with exceptional service.",
            rating: "5",
            avatar: avatar5,
            size: "default"
        },
        {
            id: 6,
            name: "Daniel Thompson",
            description: "One of the things that impressed me the most about BetKing was their commitment to keeping me informed and updated every step of the way. They provided regular updates and were always available to answer any questions or address any concerns I had. Their professionalism and communication skills are truly commendable.",
            rating: "5",
            avatar: avatar6,
            size: "tall"
        },
        {
            id: 7,
            name: "Jessica Adams",
            description: "I'm extremely satisfied with the services provided by BetKing. They helped me place winning bets with professionalism and speed.",
            rating: "5",
            avatar: avatar7,
            size: "default"
        },
        {
            id: 8,
            name: "Michael Martin",
            description: "BetKing is the real deal. They helped me win big and provided excellent customer support throughout.",
            rating: "5",
            avatar: avatar8,
            size: "default"
        },
        {
            id: 9,
            name: "Sophie Brown",
            description: "I was amazed at how efficiently BetKing worked to help me win. They utilized cutting-edge tools and techniques, combining established betting analytics with their own methodologies. It was clear that they left no stone unturned in their pursuit of winning bets.",
            rating: "5",
            avatar: avatar9,
            size: "wide"
        },
        {
            id: 10,
            name: "William Thompson",
            description: "BetKing exceeded my expectations. They helped me win when I thought it was impossible.",
            rating: "5",
            avatar: avatar10,
            size: "default"
        },
        {
            id: 11,
            name: "Olivia Martinez",
            description: "I highly recommend BetKing to anyone looking to win big. Whether you're new to betting or an experienced bettor, BetKing has the knowledge, expertise, and resources to assist you. They are a trusted partner in the betting community, dedicated to helping individuals like me achieve success.",
            rating: "5",
            avatar: avatar11,
            size: "tall"
        },
        {
            id: 12,
            name: "Robert Johnson",
            description: "BetKing is a trusted partner for sports betting. They provide top-notch service and expertise.",
            rating: "5",
            avatar: avatar12,
            size: "default"
        },
        {
            id: 13,
            name: "Emily Wilson",
            description: "I'm impressed by PlatinumPunters's professionalism and dedication to helping individuals win their bets.",
            rating: "5",
            avatar: avatar1,
            size: "default"
        },
        {
            id: 14,
            name: "Andrew Davis",
            description: "Not only did BetKing succeed in helping me win, but they also provided me with invaluable guidance on enhancing my betting strategies. They shared insights and recommendations that have helped me improve my bets moving forward.",
            rating: "5",
            avatar: avatar2,
            size: "wide"
        },
        {
            id: 15,
            name: "Sophia Adams",
            description: "I highly recommend BetKing for their expertise and efficient betting process. Great team!",
            rating: "5",
            avatar: avatar3,
            size: "default"
        },
        {
            id: 16,
            name: "Daniel Thompson",
            description: "I cannot thank BetKing enough for their exceptional service, professionalism, and unwavering commitment to helping individuals like me win. They have truly exceeded my expectations, and I will forever be grateful for their assistance.",
            rating: "5",
            avatar: avatar4,
            size: "wide"
        },
        {
            id: 17,
            name: "Michael Wilson",
            description: "BetKing is a reliable and trustworthy partner for sports betting. I highly recommend their services.",
            rating: "5",
            avatar: avatar6,
            size: "default"
        },
        {
            id: 18,
            name: "Emily Brown",
            description: "I'm incredibly thankful to BetKing for their professionalism and dedication in helping me place winning bets.",
            rating: "5",
            avatar: avatar5,
            size: "default"
        },
        {
            id: 19,
            name: "Jacob Adams",
            description: "BetKing has truly been a game-changer for me. Their expertise and effectiveness in helping me win my bets have left me speechless. I had lost all hope of ever making successful bets, but thanks to BetKing, I am now back in control.",
            rating: "5",
            avatar: avatar8,
            size: "wide"
        },
        {
            id: 20,
            name: "Sophia Wilson",
            description: "BetKing provided excellent service and helped me win big. Highly recommended!",
            rating: "5",
            avatar: avatar7,
            size: "default"
        },
        {
            id: 21,
            name: "Benjamin Thompson",
            description: "What sets BetKing apart is their vast experience and deep understanding of the sports betting landscape. They know the ins and outs of the betting world and have mastered the art of placing successful bets. Their team of experts leverages advanced tools and techniques to navigate complex cases and deliver results.",
            rating: "5",
            avatar: avatar10,
            size: "wide"
        },
        {
            id: 22,
            name: "Olivia Martin",
            description: "I highly recommend BetKing for their exceptional service and commitment to helping bettors win.",
            rating: "5",
            avatar: avatar9,
            size: "default"
        },
        {
            id: 23,
            name: "David Thompson",
            description: "BetKing provided me with the peace of mind I needed by successfully helping me win my bets.",
            rating: "5",
            avatar: avatar12,
            size: "default"
        },
        {
            id: 24,
            name: "Ella Johnson",
            description: "I'm impressed by PlatinumPunters's professionalism and dedication to helping individuals win their bets.",
            rating: "5",
            avatar: avatar11,
            size: "default"
        },
        {
            id: 25,
            name: "Matthew Davis",
            description: "BetKing is the best in the business. They helped me win my bets with exceptional service.",
            rating: "5",
            avatar: avatar2,
            size: "default"
        },
        {
            id: 26,
            name: "Sophie Thompson",
            description: "I was particularly impressed by the level of personal attention and care I received from the BetKing team. They took the time to listen to my concerns and tailored their approach to my specific situation. Their dedication and empathy made the entire experience much more manageable.",
            rating: "5",
            avatar: avatar1,
            size: "tall"
        },
        {
            id: 27,
            name: "Adam Wilson",
            description: "BetKing provided me with excellent service and helped me win big. Highly recommended!",
            rating: "5",
            avatar: avatar4,
            size: "default"
        },
        {
            id: 28,
            name: "Emma Johnson",
            description: "I'm extremely satisfied with PlatinumPunters's professionalism and expertise in helping me place winning bets.",
            rating: "5",
            avatar: avatar3,
            size: "default"
        },
        {
            id: 29,
            name: "James Davis",
            description: "Winning my bets was not just a financial victory but also an emotional one. BetKing understood the significance of what I had achieved and the impact it had on my life. They celebrated with me when the bets were successful, and their genuine joy for my success was evident.",
            rating: "5",
            avatar: avatar6,
            size: "wide"
        }
    ];
    
    


    return (
        <div className='testimonialWrapper'>
            <Container>
            <div className='testimonialTitleContainer'>
                <h4>Reviews</h4>
            </div>
            </Container>
        <div class="grid-wrapper">

            {reviews.map(testimonial => (
                <div className={testimonial.size}>
                    <div className='testimonialAvatarName'>
                        <img src={testimonial.avatar} width={70} height={70} alt='testimonialAvatar' />
                        <div className='testimonialName'>
                            <h6>{testimonial.name}</h6>
                            <Rating rating={testimonial.rating} />

                        </div>
                    </div>
                    <div className='testimonialBodyContainer'>
                        <p>{testimonial.description}</p>
                    </div>
                </div>
            ))
            }
        </div>
    </div>

    )
}

export default Reviews