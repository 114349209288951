import React from 'react'
import './styles.css'
import { Container, Spinner } from 'react-bootstrap'
import YouTubeEmbed from '../youtubeEmbed'

const Highlights = ({ highlights, loading }) => {
    return (
            <div className='highlightsWrapper'>
                {loading ?
                    <div style={{ height: 450, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Spinner animation="grow" style={{ backgroundColor: "#000" }} />
                    </div>
                    :
                    <div className='highlightsContentContainer'>
                        {
                            highlights?.map((highlight) => {
                                return (
                                    <div className='highlightsContent'>
                                        <YouTubeEmbed youtubeUrl={highlight?.strVideo} />
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
    )
}

export default Highlights